<template>
  <v-row class="mx-0 my-3 flex justify-center">
    <!--  -->
    <v-col cols="12">
      <SectionHeader title="Movimentações" />
    </v-col>
    <!--  -->
    <v-col class="pa-0 mb-4" v-for="item in inList" :key="item.title">
      <CardInfo v-bind="item" />
    </v-col>

    <!--  -->
    <v-col cols="12">
      <SectionHeader title="Receitas x Despesas mensal" />
    </v-col>

    <!--  -->
    <ResumeCardList :items="resume" use-links :month="month" />

    <!--  -->
    <v-col cols="12" class="pa-2 pt-0 mb-4">
      <GraphicLine v-bind="chartSettings" />
    </v-col>

    <!--  -->
    <v-col cols="12">
      <SectionHeader title="Relatórios" />
    </v-col>

    <!--  -->
    <v-col
      cols="12"
      sm="4"
      class="pa-0 mb-4"
      v-for="item in reportsList"
      :key="item.title"
    >
      <CardInfo v-bind="item" />
    </v-col>

    <!--  -->
    <v-col cols="12">
      <SectionHeader title="Tabelas Auxiliares" />
    </v-col>

    <!--  -->
    <v-col class="pa-0 mb-4" v-for="item in tablesList" :key="item.title">
      <CardInfo v-bind="item" />
    </v-col>
  </v-row>
</template>

<script>
import { currencyBR } from "klawtil";

import SectionHeader from "@/components/SectionHeader";
import GraphicLine from "@/components/Graphic/GraphicLine";
import CardInfo from "@/components/CardInfo";
import ResumeCardList from "../_shared/ResumeCardList";

export default {
  components: {
    SectionHeader,
    CardInfo,
    GraphicLine,
    ResumeCardList
  },

  props: {
    chart: { type: Object, default: () => ({ income: [], expenses: [] }) },
    resume: { type: Object, default: () => ({}) },
    month: String
  },

  data() {
    return {};
  },

  computed: {
    inList() {
      return [
        {
          label: "Movimentações financeiras",
          icon: "mdi-cash",
          value: this.resume.count,
          to: `/financials/search?month=${this.month}` // incluir o mes
        },
        {
          label: "Nova movimentação financeira",
          icon: "mdi-plus",
          // to: "/"
          click: () =>this.$emit('click:add')
        },
        {
          label: "Nova cobrança",
          icon: "mdi-plus",
          click: () =>this.$emit('click:add_charge')
        }
      ];
    },

    chartSettings() {
      return {
        // Título do gráfico
        title: "Receitas x Despesas",
        // Tipo de cada série
        types: ["line", "line"],
        // Valor dentro da série para ser usado como valor
        values: "amount",
        // Valor dentro da série para ser usado como label do eixo x
        xLabels: "day",
        // Nomes das Séries
        seriesNames: ["Receitas", "Despesas"],
        // Cores das Séries
        colors: ["green", "red"],
        //
        series: [this.chart.income, this.chart.expenses],
        // formater
        labelFormatter: params => {
          return `Dia ${params[0].axisValue} <br />
            ${params[0].seriesName}: ${currencyBR(params[0].value)} <br />
            ${params[1].seriesName}: ${currencyBR(params[1].value)}`;
        },
        // formatador como string
        _labelFormatter: `
          Dia {b0} <br /> 
          {a0}: R$ {c0}<br />
          {a1}: R$ {c1}
          `
      };
    },

    reportsList() {
      return [
        {
          label: "Devedores",
          icon: "mdi-account-cash",
          to: "/financials/reports/debtors"
        },

        {
          label: "Balancete",
          icon: "mdi-swap-vertical",
          to: "/financials/reports/balance"
        },
        {
          label: "Balancete por categoria",
          icon: "mdi-swap-vertical",
          to: "/financials/reports/category"
        }
      ];
    },
    tablesList() {
      return [
        {
          label: "Gateways",
          icon: "mdi-bank-outline",
          to: "/financials/gateways",
          color: "grey"
        },
        {
          label: "Conexões",
          icon: "mdi-connection",
          to: "/financials/connections",
          color: "grey"
        },
        {
          label: "Status",
          icon: "mdi-sticker-check-outline",
          to: "/financials/statuses",
          color: "grey"
        },
        {
          label: "Categorias",
          icon: "mdi-tag-outline",
          to: "/financials/categories",
          color: "grey"
        },
        {
          label: "Formas de Pagamento",
          icon: "mdi-arrow-decision",
          to: "/financials/methods",
          color: "grey"
        }
      ];
    }
  }
};
</script>

<style>
</style>
