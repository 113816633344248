<template>
  <KDialog
    :title="title"
    :visible="visible"
    :loading="loading"
    :actions="dialogActions"
    @click:save="save"
    @click:close="close"
  >
    <KForm ref="form">
      <v-row class="my-0">
        <v-col cols="12">
          <SectionHeader title="Informações Básicas" subtitle="" />
        </v-col>

        <!-- Receita/Despesa -->
        <v-col cols="6" sm="4" class="py-1">
          <v-select
            label="Receita/Despesa"
            item-value="value"
            item-text="text"
            :items="multiplierItems"
            v-model="form.multiplier"
            :error-messages="errors.multiplier"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Categoria -->
        <v-col cols="6" sm="8" class="py-1">
          <v-autocomplete
            label="Categoria da Movimentação"
            item-value="id"
            item-text="full"
            v-model="form.category_id"
            :error-messages="errors.category_id"
            :items="categoryItems"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Descrição -->
        <v-col cols="12" class="py-1">
          <v-text-field
            label="Descrição da Movimentação"
            v-bind="inputSettings"
            v-model="form.description"
            :error-messages="errors.description"
          ></v-text-field>
        </v-col>

        <!-- Valor -->
        <v-col cols="6" class="py-1">
          <KInputMoney
            label="Valor"
            v-model="form.amount"
            :error-messages="errors.amount"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Status -->
        <v-col cols="6" class="py-1">
          <v-select
            label="Status do Pagamento"
            item-value="id"
            item-text="name"
            v-model="form.status_id"
            :error-messages="errors.status_id"
            :items="statusItems"
            v-bind="inputSettings"
          />
        </v-col>

        <v-col cols="12">
          <SectionHeader
            title="Informação do Pagamento"
            subtitle="Informe estes campos quando esta movimentação estiver concluída"
          />
        </v-col>

        <!-- Data de Pagamento -->
        <v-col cols="6" class="py-1">
          <KInputDate
            label="Data de Pagamento"
            v-model="form.payed_at"
            :error-messages="errors.payed_at"
            v-bind="inputSettings"
            hint="Informar somente quando estiver paga"
            persistent-hint
          />
        </v-col>

        <!-- Método -->
        <v-col cols="6" class="py-1">
          <v-select
            label="Forma de Pagamento"
            item-value="id"
            item-text="name"
            v-model="form.method_id"
            :error-messages="errors.method_id"
            :items="methodItems"
            v-bind="inputSettings"
          />
        </v-col>

        <v-col cols="12">
          <SectionHeader
            title="Cobrança"
            subtitle="Campo obrigatórios caso esta movimentação venha a ser transformada em uma cobrança"
          />
        </v-col>

        <!-- cliente -->
        <v-col cols="12" sm="6">
          <PeopleAutocomplete
            outlined
            clearable
            dense
            label="Cliente"
            hint="Digite para começar a pesquisar"
            persistent-hint
            v-model="form.customer_id"
            :error-messages="errors.customer_id"
            :search-params="{}"
            v-bind="$attrs"
          />
        </v-col>

        <!-- Data de Vencimento -->
        <v-col cols="12" sm="6">
          <KInputDate
            label="Vencimento"
            v-model="form.expires_at"
            :error-messages="errors.expires_at"
            v-bind="inputSettings"
          />
        </v-col>

        <v-col cols="12">
          <SectionHeader
            title="Referência"
            subtitle="É possível indicar que esta movimentação estará vinculada a outro módulo do sistema. É preciso selecionar o módulo e qual o código referente a este módulo."
          />
        </v-col>

        <!-- Módulo -->
        <v-col cols="6">
          <v-select
            label="Módulo"
            item-value="value"
            item-text="text"
            v-model="form.module"
            :error-messages="errors.module"
            :items="moduleItems"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- ID do módulo -->
        <v-col cols="6">
          <v-text-field
            label="ID do Módulo"
            v-model="form.module_id"
            :error-messages="errors.module_id"
            v-bind="inputSettings"
          />
        </v-col>
      </v-row>
      <!-- <v-btn @click="clear">limpar</v-btn> -->
    </KForm>

    <!-- <v-row class="my-0">
        <v-col cols="12">
          <SectionHeader title="Ações" />
        </v-col>

        <v-col cols="12">
          <v-card outlined rounded="lg">
            <ExplicatedAction
              v-once
              title="Gerar Cobrança"
              color="primary"
              event-name="click:billet"
              @click:billet="onClickBillet"
              description="Gerar uma cobrança a partir desta movimentação financeira. Esta precisa ser uma movimentação financeira de entrada e não pode estar paga e também precisa de um cliente"
            />
          </v-card>
        </v-col>
       </v-row> -->

    <!-- {{ form }} -->
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KLabel from "@/components/KLabel";
import KDialog from "@/components/KDialog";
import KInputMoney from "@/components/KInput/Money";
import KInputMasked from "@/components/KInput/Masked";
import KInputDate from "@/components/KInput/Date";
import SectionHeader from "@/components/SectionHeader";
import PeopleAutocomplete from "@/components/PeopleAutocomplete";

import ExplicatedAction from "@/components/ExplicatedAction";

export default {
  components: {
    KForm,
    KLabel,
    KDialog,
    KInputDate,
    KInputMoney,
    KInputMasked,
    SectionHeader,
    ExplicatedAction,
    PeopleAutocomplete
  },

  data() {
    return {
      visible: false,
      loading: false,
      title: null,
      form: {},
      errors: {},
      inputSettings: {
        dense: true,
        outlined: true
      },
      dialogActions: [
        {
          label: "Salvar",
          eventName: "click:save"
        }
      ]
    };
  },

  watch: {
    // title(n, o) {
    //   console.log(n, o);
    // }
  },

  computed: {
    multiplierItems() {
      return this.$store.getters["financials/multipliers"];
    },
    moduleItems() {
      return this.$store.getters["financials/modules"];
    },
    categoryItems() {
      return this.$store.getters["financial_categories/all"];
    },
    methodItems() {
      return this.$store.getters["financial_methods/all"];
    },
    gatewayItems() {
      return this.$store.getters["financial_gateways/all"];
    },
    connectionItems() {
      return this.$store.getters["financial_connections/allActive"];
    },
    statusItems() {
      return this.$store.getters["financial_statuses/allImportant"];
    },
    moduleName() {
      return this.moduleItems.find(module => module.value === this.form.module);
    }
  },

  methods: {
    async save() {
      try {
        this.errors = {};
        this.loading = true;
        await this.$store.dispatch("financials/save", this.form);
        this.loading = false;
        this.$emit("save");
        this.close();
      } catch (error) {
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },
    open(payload) {
      this.visible = true;
      this.form = payload;
      // console.log({ ...payload });

      if (payload.id) {
        this.title = `Editar movimentação #${payload.id}`;
      } else {
        if (payload.multiplier === "1") {
          this.title = "Nova receita";
        } else if (payload.multiplier === "-1") {
          this.title = "Nova despesa";
        } else {
          this.title = "Nova movimentação";
        }
      }

      // this.title = payload.id;
      // this.$set(this.form, "multiplier", multiplier);
    },

    async clear() {
      this.form = {};
      this.errors = {};
    },

    close() {
      this.clear();
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
