<template>
  <KDialog
    max-width="800"
    :visible="visible"
    :loading="loading"
    :title="title"
    :__actions="dialogActions"
    @click:update="onBatchUpdateClick"
    @click:close="close"
  >
    <v-toolbar flat>
      <v-col class="d-flex flex-nowrap align-center">
        <v-chip> {{ updatedCount }}/{{ charges.length }}</v-chip>
      </v-col>
      <v-col>
        Próximo: <v-chip> {{ next }} </v-chip>
      </v-col>
      <v-col>
        <v-text-field
          label="Intervalo"
          suffix="ms"
          v-model="intervalInMs"
          dense
          outlined
          hide-details
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        ><v-btn depressed rounded v-if="intervalObject" @click="onPauseClick">
          <v-icon left>mdi-pause</v-icon>
          Parar
        </v-btn>
        <v-btn depressed rounded v-else @click="onBatchUpdateClick">
          <v-icon left>mdi-play</v-icon>
          Iniciar
        </v-btn>
      </v-col>
    </v-toolbar>

    <!--  -->
    <v-progress-linear
      color="blue"
      :value="(updatedCount / charges.length) * 100"
    ></v-progress-linear>

    <v-card outlined>
      <v-list class="bordered">
        <v-list-item v-for="(charge, i) in charges" :key="charge.id">
          <!--  -->
          <v-list-item-avatar>
            <v-icon v-if="successList.includes(charge.id)" color="blue"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon
              v-else-if="errorIds.includes(charge.id)"
              color="red"
              :title="errorList[charge.id]"
              >mdi-alert-circle-outline</v-icon
            >
            <v-icon v-else color="grey">mdi-clock-outline</v-icon>
          </v-list-item-avatar>

          <!--  -->
          <v-list-item-content>
            <v-list-item-title>
              <span class="grey--text">#{{ charge.id }}</span>
              {{ charge.description }}
            </v-list-item-title>

            <!--  -->
            <v-list-item-subtitle>
              {{ charge.public_id }}
              <StatusChip :status="charge.status" />
              <KChip
                >Última atualização {{ charge.updated_at | timeAgo }}</KChip
              >
              <KChip v-if="charge.is_charge" blue>Cobrança</KChip>
              <KChip v-if="charge.is_expired" red>Atrasada</KChip>
            </v-list-item-subtitle>
          </v-list-item-content>

          <!--  -->
          <v-list-item-action>
            <v-progress-circular
              v-if="chargeLoading.includes(charge.id)"
              indeterminate
              width="2"
            ></v-progress-circular>
            <v-btn icon v-else @click="onUpdateClick(charge, i)">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </KDialog>
</template>

<script>
import KChip from "@/components/KChip";
import KDialog from "@/components/KDialog";
import SectionHeader from "@/components/SectionHeader";

import StatusChip from "../_shared/StatusChip";

export default {
  components: {
    KChip,
    KDialog,
    StatusChip,
    SectionHeader,
  },

  data() {
    return {
      // cobranças
      charges: [], // lista
      chargeLoading: [], // lista de ids da cobrança que está sendo carregada no momento
      successList: [], // lista dos atualizados em lote

      // Armaza os erros como chave e a mensagem de erro como valor
      // Ex.: { 5: 'A conexão não está mais ativa', 6: 'Não encontrado no gateway' }
      errorList: {},
      // dialog
      visible: false,
      loading: false,
      title: "Atualização de Cobranças em Lote",
      dialogActions: [{ label: "Atualizar", eventName: "click:update" }],
      //
      intervalObject: null, // objeto que guarda o intervalo
      intervalInMs: 1000, // numero que guarda o numero de ms entre as requisições
    };
  },

  computed: {
    /**
     * Retorma somente os ids das atualizações com erro
     */
    errorIds() {
      return Object.keys(this.errorList).map(key => +key);
    },

    /**
     * Mostra o total de atualizados como sendo a soma dos
     * itens de sucesso e a soma dos itens com erro
     */
    updatedCount() {
      return this.successList.length + this.errorIds.length;
    },

    /**
     * Computa o próximo a ser atualizado
     */
    next() {
      // Calcula o próximo id levando em consideração os itens de sucesso,
      // o items que estão com erro e os itens que estão em andamento
      const nextId = this.charges
        .map(item => item.id)
        .find(
          id =>
            !this.successList.includes(id) &&
            !this.errorIds.includes(id) &&
            !this.chargeLoading.includes(id)
        );

      return nextId;
    },
  },

  methods: {
    /**
     * Atualiza em lote
     */
    async onBatchUpdateClick() {
      try {
        // Ativa o loading
        this.loading = true;

        // Zera os valores iniciais
        this.chargeLoading = [];
        this.successList = [];
        this.errorList = {};

        // Função que faz o update
        const update = () => {
          // Pega o item que será colocado em seguida
          const next = this.charges.find(charge => charge.id == this.next);

          // Se existir um next
          if (next) {
            // Ativa a atualização do item específico
            this.onUpdateClick(next);
          } else {
            // Caso não exista um next, encerra
            this.$message.success("Atualização em lote finalizada");
            this.onPauseClick();
          }
        };

        // Ativa o intervalo
        this.intervalObject = setInterval(() => {
          update();
        }, this.intervalInMs);
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
      }
    },

    /**
     * Ao clicar em paus
     */
    onPauseClick() {
      clearInterval(this.intervalObject);
      this.intervalObject = null;
      this.loading = false;
      this.chargeLoading = [];
    },

    /**
     * Ativa a atualização em lote ao clicar no botão de iniciar
     */
    async onUpdateClick(charge) {
      try {
        // Adiciona a cobrança atual na lista de loading
        this.chargeLoading.push(charge.id);

        // Executa a atualização no backend
        const { data } = await this.$api.patch(
          `/financials/charges/${charge.id}/force`
        );

        // Se não acontecer erro na requisição anterior, adiciona o id
        // na lista de requisções concluídas com sucesso. Caso haja erro,
        // cai no catch
        this.successList.push(charge.id);

        // Localiza a posição da cobrança atual na lista de loading e remove
        this.removeChargeFromLoading(charge);

        // Acha o índice da cobrança atual na lista de cobranças
        const index = this.charges.findIndex(item => item.id === charge.id);

        // Se o índice é diferente de -1, atualize o status do item na lista
        if (index !== -1) {
          this.$set(this.charges, index, data.data);
        }
        // Se o índice for -1 é sinal que não foi localizada, ou seja, não existe
        // mais o item a atualizar e encerra a atualização em lote
        else {
          this.onPauseClick();
        }
      } catch (error) {
        // Mensagem de erro
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "Erro ao tentar atualizar o status da cobrança";

        // Inclui o erro na lista de erros
        this.$set(this.errorList, +charge.id, message);

        // Remove o item da lista de loading
        this.removeChargeFromLoading(charge);

        // Mosta a mensagem de erro
        this.$message.serverError(error);
      }
    },

    /**
     * Remove a cobrança da lista de loading
     */
    removeChargeFromLoading(charge) {
      // Localiza o índice na lista de loading
      const idx = this.chargeLoading.findIndex(id => id === charge.id);
      // Remove-a da lista
      this.$delete(this.chargeLoading, idx);
    },

    /**
     * Carrega os dados iniciais ao abrir a dialog
     */
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/financials", {
          is_charge: 1,
          status_id: 2,
          paginate: false,
          full: true,
          order_by: "updated_at",
          order_direction: "ASC",
        });

        this.charges = data.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
      }
    },

    /**
     * Abre a dialog
     */
    async open() {
      try {
        this.visible = true;
        this.load();
      } catch (error) {}
    },

    /**
     * Fecha a dialog e reseta os valores iniciais
     */
    close() {
      this.onPauseClick();
      this.visible = false;
      this.successList = [];
      this.chargeLoading = [];
      this.errorList = {};
    },
  },
};
</script>

<style>
</style>
