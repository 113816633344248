<!--

  <GraphicLine v-bind="chartSettings" />


    chartSettings = {
       // Título do gráfico
        title: "Receitas x Despesas",
        // Tipo de cada série
        types: ["line", "line"],
        // Valor dentro da série para ser usado como valor
        values: "amount",
        // Valor dentro da série para ser usado como label do eixo x
        xLabels: "day",
        // Nomes das Séries
        seriesNames: ["Receitas", "Despesas"],
        // Cores das Séries
        colors: ["green", "red"],
        
        // Dados vindos da api
        series: [this.chart.income, this.chart.expenses],

        // Dados crus. Duas séries de dados com todos os vlores como numeros no array
        series: [ [0,2,4,6], [1,3,5,7] ]

        // formatador da tooltip como função
        labelFormatter: params => {
          return `Dia ${params[0].axisValue} <br />
            ${params[0].seriesName}: ${currencyBR(params[0].value)} <br />
            ${params[1].seriesName}: ${currencyBR(params[1].value)}`;
        },

        // formatador como string
        labelFormatter: `
          Dia {b0} <br /> 
          {a0}: R$ {c0}<br />
          {a1}: R$ {c1}
          `
      };


-->
<template>
  <v-card
    :outlined="outline"
    flat
    min-height="250"
    min-width="350"
    class="rounded-lg"
  >
    <v-sheet :color="sheetColor" :height="hasSlot ? 150 : 230">
      <v-chart
        :options="data"
        :init-options="initOptions"
        ref="bar"
        style="width: 100%"
        :style="{ height: hasSlot ? '180px' : '250px' }"
        autoresize
      />
    </v-sheet>

    <v-card-text v-if="hasSlot">
      <slot></slot>
    </v-card-text>
    <v-divider v-if="title"></v-divider>
    <v-card-actions v-if="title" class="caption" style="align-items: center">
      <p class="ma-0 d-block text-truncate">{{ title }}</p>
    </v-card-actions>
  </v-card>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";

import Colors from "colors-formater";

export default {
  name: "graphic-line",
  components: {
    "v-chart": ECharts
  },
  props: {
    // Título que aparcerá no rodapé do v-card
    title: String,
    // Array contendo arrays de objetos com dados
    // [ [ {obj1}, {obj2} ], [ {obj3}, {obj4} ] ]
    // Neste exemplo, temos duas séries
    series: { type: Array, default: () => [] },
    // O nome, dentro dos dados de uma série, da chave que será o valor do eixo x
    // Ex.: no objeto, {value: 20, date:2020-02-02}
    // Posso usar "value" ou "date". O valor escolhido irá ocupar o eixo x
    xLabels: String,
    // O nome da chave que terá seus dados no eixo y
    // yLabels: String,
    // O nome da chave que contém os valores
    values: String,
    // O tipo do gráfico, podendo ser bar ou line. Precisa definir
    // um valor para cada série de dados. Pode também ser uma string,
    // nesse caso, todas as séries serão iguais com o mesmo tipo
    types: [String, Array], //Um tipo para cada serie
    // Nomes para as séries:
    seriesNames: [String, Array],
    // Cores para as séries:
    colors: [String, Array],
    // Atributos para o v-card do gráfico
    // Altura da elevação
    elevation: { type: [String, Number], default: 1 },
    // Cor do v-card
    color: { type: String, default: "#FBFBFB" },
    // Cor interna do gráfico
    sheetColor: { type: String, default: "white" },
    // Se o v-card externo é outlined
    outline: { type: Boolean, default: true },
    // label-formatter. Função que passa os parâmetros definidos em
    // https://echarts.apache.org/en/option.html#tooltip.formatter
    // e formata o label do gráfico.
    labelFormatter: {
      type: [String, Function]
      // default: "{a} <br/>{b} : {c} ({d}%)"
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      }
    };
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
    data() {
      const data = {
        xAxis: {
          data:
            (this.series[0] &&
              this.series[0].map(item => item[this.xLabels])) ||
            []
        },
        yAxis: {
          type: "value"
        },
        series:
          Array.isArray(this.series) &&
          this.series.map((serie, i) => {
            return {
              name: Array.isArray(this.seriesNames)
                ? this.seriesNames[i]
                : "Série " + (i + 1),
              color: Array.isArray(this.colors) ? this.colors[i] : null,
              type: Array.isArray(this.types) ? this.types[i] : this.types,
              data: serie.map(value => value[this.values])
            };
          }),
        tooltip: {
          trigger: "axis",
          formatter: this.labelFormatter
        }
      };

      // Se cores forem definidas
      if (Array.isArray(this.colors)) data.color = this.colors;

      return data;
    }
  }
};
</script>

<style scoped>
.echarts div {
  width: 200px;
  height: 100%;
}
.echarts {
  width: 100%;
  height: 100%;
}
</style>
