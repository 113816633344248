<template>
  <KChip v-bind="settings">{{ status.name }}</KChip>
</template>

<script>
import KChip from "@/components/KChip";
export default {
  components: {
    KChip
  },

  props: {
    status: Object
  },

  computed: {
    settings() {
      let color = "";
      switch (this.status.status) {
        case 1:
          color = "blue";
          break;
        case 0:
          color = "orange";
          break;
        case -1:
          color = "grey";
          break;
      }

      if (this.status.slug === "other") color = "grey";

      return { color, xSmall: true };
    }
  }
};
</script>

<style>
</style>
