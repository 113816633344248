<template>
  <v-toolbar flat>
    <v-btn icon @click="onRefresh">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-col class="pa-0" cols="3">
      <KInputMonth
        dense
        mandatory
        solo
        flat
        hide-details
        placeholder="Filtrar por mês"
        required
        v-model="form.month"
      ></KInputMonth>
    </v-col>
  </v-toolbar>
</template>

<script>
import KInputMonth from "@/components/KInput/Month";

export default {
  components: {
    KInputMonth
  },

  data() {
    return {
      form: {},
      errors: {}
    };
  },

  watch: {
    form: {
      deep: true,
      handler(nVal, oVal) {
        this.onChangeMonth();
      }
    }
  },

  methods: {
    /**
     *
     */
    onRefresh() {
      this.$emit("filter", this.form);
    },

    /**
     *
     */
    onChangeMonth() {
      this.$emit("filter", this.form);
    }
  }
};
</script>

<style>
</style>
