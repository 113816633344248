<template>
  <!--  -->
  <v-card-text>
    <v-row>
      <v-col cols="12" :md="showButton ? 6 : 12">
        <v-row class="ma-0 font-weight-bold"> {{ title }} </v-row>
        <v-row class="ma-0">
          {{ description }}
        </v-row>
      </v-col>
      <v-col v-if="showButton" cols="12" md="6" class="d-flex justify-end align-center"
        ><v-btn text rounded :color="color" @click="$emit(eventName)">
          {{ title }}
        </v-btn></v-col
      >
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    color: String,
    eventName: String,
    showButton: {type: Boolean, default: true},
  }
};
</script>

<style>
</style>
