<template>
  <v-row class="mx-0 my-3 flex justify-center">
    <v-col
      v-for="item in cardList"
      :key="item.title"
      class="pa-0 mb-4"
      v-bind="gridSettings"
    >
      <CardInfo v-bind="item" />
    </v-col>
  </v-row>
</template>

<script>
import VisibleBy from "@/components/VisibleBy";
import CardInfo from "@/components/CardInfo";

export default {
  components: {
    //
    VisibleBy,

    //
    CardInfo,
  },

  props: {
    items: Object,
    // Cria links para os itens dos cards, levando diretamente para a página de busca
    // com os parâmetros já preenchidos
    useLinks: Boolean,
    // Inclui o mês nos parâmetros de busca.
    // Usado somente quando useLinks está ativo.
    month: String,
    // Se for true, adiciona uma variação específica para o tamanho XL, permitindo
    // que em telas muito grandes os 6 cards fiquem na mesma linha. Quando for false,
    // o máximo de cards por linha é 3.
    fluid: Boolean,
  },

  computed: {
    cardList() {
      const items = this.items;
      if (!items) return {};

      return [
        {
          value: this.items.to_pay,
          icon: "mdi-sort-clock-ascending-outline",
          color: "red",
          type: "currency",
          label: "A pagar",
          to: this.useLinks
            ? `/financials/search?month=${this.month}&multiplier=-1&status_id=2`
            : null,
        },
        {
          value: this.items.to_receive,
          icon: "mdi-sort-clock-descending-outline",
          color: "green",
          type: "currency",
          label: "A receber",
          to: this.useLinks
            ? `/financials/search?month=${this.month}&multiplier=1&status_id=2`
            : null,
        },
        {
          value: this.items.canceled,
          icon: "mdi-close-circle-outline",
          color: "grey",
          type: "currency",
          label: "Canceladas",
          to: this.useLinks
            ? `/financials/search?month=${this.month}&status_id=5`
            : null,
        },
        {
          value: this.items.income,
          icon: "mdi-arrow-up-thin",
          color: "green",
          type: "currency",
          label: "Receitas",
          to: this.useLinks
            ? `/financials/search?month=${this.month}&multiplier=1&status_id=1`
            : null,
        },
        {
          value: this.items.expenses,
          icon: "mdi-arrow-down-thin",
          color: "red",
          type: "currency",
          label: "Despesas",
          to: this.useLinks
            ? `/financials/search?month=${this.month}&multiplier=-1&status_id=1`
            : null,
        },
        {
          value: this.items.balance,
          icon: "mdi-swap-vertical",
          color: this.items.balance >= 0 ? "green" : "red",
          type: "currency",
          label: "Saldo (Receitas - Despesas)",
        },
      ];
    },

    gridSettings() {
      const settings = {
        cols: "12",
        sm: "6",
        md: "4",
      };
      if (this.fluid) settings.xl = "2";

      return settings;
    },
  },
};
</script>

<style>
</style>
