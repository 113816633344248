<template>
  <KDialog
    :title="`Nova Cobrança Avulsa`"
    :visible="visible"
    :loading="loading"
    :actions="dialogActions"
    @click:save="save"
    @click:close="close"
  >
    <KForm ref="form">
      <v-row class="my-0">
        <v-col cols="12">
          <SectionHeader title="Informações Básicas" />
        </v-col>

        <!-- Receita/Despesa -->
        <v-col cols="6" class="py-1">
          <v-select
            label="Receita/Despesa"
            item-value="value"
            item-text="text"
            disabled
            :items="multiplierItems"
            v-model="form.multiplier"
            :error-messages="errors.multiplier"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Categoria -->
        <v-col cols="6" class="py-1">
          <v-autocomplete
            label="Categoria"
            item-value="id"
            item-text="full"
            v-model="form.category_id"
            :error-messages="errors.category_id"
            :items="categoryItems"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Data de Vencimento -->
        <v-col cols="6" class="py-1">
          <KInputDate
            label="Data de Vencimento"
            v-model="form.expires_at"
            :error-messages="errors.expires_at"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Conexão do gateway -->
        <v-col cols="6" class="py-1">
          <v-select
            label="Conexão"
            item-value="id"
            item-text="name"
            hint="Conexão com o banco que gerará o boleto"
            persistent-hint
            :error-messages="errors.connection_id"
            :items="connectionItems"
            v-model="form.connection_id"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Valor -->
        <v-col cols="6" class="py-1">
          <KInputMoney
            label="Valor"
            v-model="form.amount"
            :error-messages="errors.amount"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- cliente -->
        <v-col cols="6" class="py-1">
          <PeopleAutocomplete
            outlined
            clearable
            dense
            label="Cliente"
            hint="Digite para começar a pesquisar"
            persistent-hint
            v-model="form.customer_id"
            :error-messages="errors.customer_id"
            :search-params="{}"
            v-bind="$attrs"
          />
        </v-col>

        <!-- Descrição -->
        <v-col cols="12" class="py-1">
          <v-text-field
            label="Descrição"
            v-bind="inputSettings"
            v-model="form.description"
            :error-messages="errors.description"
            counter="70"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <SectionHeader
            title="Referência"
            subtitle="É possível indicar que esta movimentação estará vinculada a outro módulo do sistema. É preciso selecionar o módulo e qual o código referente a este módulo."
          />
        </v-col>

        <!-- Módulo -->
        <v-col cols="6">
          <v-select
            label="Módulo"
            item-value="value"
            item-text="text"
            v-model="form.module"
            :error-messages="errors.module"
            :items="moduleItems"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- ID do módulo -->
        <v-col cols="6">
          <v-text-field
            label="ID do Módulo"
            v-model="form.module_id"
            :error-messages="errors.module_id"
            v-bind="inputSettings"
          />
        </v-col>
      </v-row>
      <!-- <v-btn @click="clear">limpar</v-btn> -->
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KLabel from "@/components/KLabel";
import KDialog from "@/components/KDialog";
import KInputMoney from "@/components/KInput/Money";
import KInputMasked from "@/components/KInput/Masked";
import KInputDate from "@/components/KInput/Date";
import SectionHeader from "@/components/SectionHeader";
import PeopleAutocomplete from "@/components/PeopleAutocomplete";

import ExplicatedAction from "@/components/ExplicatedAction";

export default {
  components: {
    KForm,
    KLabel,
    KDialog,
    KInputDate,
    KInputMoney,
    KInputMasked,
    SectionHeader,
    ExplicatedAction,
    PeopleAutocomplete
  },

  data() {
    return {
      visible: false,
      loading: false,
      form: {
        multiplier: "1"
      },
      errors: {},
      inputSettings: {
        dense: true,
        outlined: true
      },
      dialogActions: [
        {
          label: "Nova cobrança",
          eventName: "click:save"
        }
      ]
    };
  },

  computed: {
    multiplierItems() {
      return this.$store.getters["financials/multipliers"];
    },
    moduleItems() {
      return this.$store.getters["financials/modules"];
    },
    categoryItems() {
      return this.$store.getters["financial_categories/all"];
    },
    methodItems() {
      return this.$store.getters["financial_methods/all"];
    },
    gatewayItems() {
      return this.$store.getters["financial_gateways/all"];
    },
    connectionItems() {
      return this.$store.getters["financial_connections/allActive"];
    },
    statusItems() {
      return this.$store.getters["financial_statuses/allImportant"];
    },
    moduleName() {
      return this.moduleItems.find(module => module.value === this.form.module);
    }
  },

  methods: {
    async save() {
      try {
        this.errors = {};
        this.loading = true;
        await this.$store.dispatch("financials/addCharge", this.form);
        this.loading = false;
        this.$emit("save");
        this.close();
      } catch (error) {
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },
    open() {
      this.visible = true;
      this.$set(this.form, "multiplier", "1");
    },

    async clear() {
      this.form = {};
      this.errors = {};
    },

    close() {
      this.clear();
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
