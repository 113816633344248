
<!--
  Componente que oculta ou exibe um valor a depender do environment e/ou 
  da permissão do usuário.

  // Mostra somente no ambiente local e staging
  <VisibleBy :envs="['local', 'stagign']"> Olar </VisibleBy>

  // Mostra somente para os grupos devs e admin
  <VisibleBy :roles="['dev', 'admin']"> Olar </VisibleBy>

  // Mostra somente para o grupo dev OU para o ambiente local
  <VisibleBy :envs="['local']" :roles="['dev']"> Olar </VisibleBy>

  // Ao colocar o atributo both, o valor será mostrado
  // somente para o grupo dev E para o ambiente local
  <VisibleBy :envs="['local']" :roles="['dev']" both> Olar </VisibleBy>

-->
<template>
  <component :is="tag" v-if="visible">
    <slot></slot>
  </component>
</template>

<script>
import { intersect } from "../../plugins/utils";
export default {
  props: {
    // Define os ambientes que isso ficará visível
    envs: { type: Array, default: () => [] },
    // Define as permissões que isso vai ficarvisível
    roles: { type: Array, default: () => [] },
    // Precisa casar o ambiente e as permissões
    both: Boolean,
    // Qual a tag que será exibida. O padrão é div
    tag: { type: String, default: "div" }
  },
  computed: {
    visible() {
      const inRoles =
        intersect(this.$api.getCurrentRoles(), this.roles).length >= 1;

      const inEnvs = this.envs.includes(process.env.VUE_APP_ENV);

      if (!this.both) return inRoles || inEnvs;
      else return inRoles && inEnvs;
    }
  }
};
</script>

<style>
</style>
