<template>
  <KPage>
    <FilterBar @filter="onFilter" />
    <SectionDashboard :resume="resume" :chart="chart" :month="filter.month" @click:add="openToAdd"
    @click:add_charge="openToAddCharge" />

    <SpeedDial
      :visible="!loading"
      :actions="speedDialActions"
      @click:add="openToAdd"
      @click:add_charge="openToAddCharge"
      @click:batch_update="openToBatchUpdate"
    ></SpeedDial>
    <DialogAddCharge ref="dialog_add_charge" />
    <DialogSave ref="dialog_save" />
    <DialogBatchUpdate ref="dialog_batch_update" />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import SpeedDial from "@/components/SpeedDial";

import FilterBar from "./FilterBar";
import SectionDashboard from "./SectionDashboard";

import DialogAddCharge from "../_shared/DialogAddCharge";
import DialogSave from "../_shared/DialogSave";
import DialogBatchUpdate from "../batch_update/DialogBatchUpdate";
export default {
  name: "page-index",

  components: {
    KPage,
    SpeedDial,
    SectionHeader,
    FilterBar,
    SectionDashboard,
    DialogAddCharge,
    DialogSave,
    DialogBatchUpdate
  },

  data() {
    return {
      loading: false,
      chart: { income: [], expenses: [] },
      resume: {},
      //
      filter: {}
    };
  },

  computed: {
    speedDialActions() {
      return [
        {
          eventName: "click:add",
          label: "Nova Movimentação",
          icon: "mdi-swap-vertical",
          color: "blue"
        },
        {
          eventName: "click:add_charge",
          label: "Nova Cobrança",
          icon: "mdi-barcode",
          color: "black"
        },
        {
          eventName: "click:batch_update",
          label: "Atualizar em Lote",
          icon: "mdi-credit-card-refresh-outline",
          color: "orange"
        }
      ];
    }
  },

  methods: {
    /**
     *
     */
    async onFilter(payload) {
      try {
        // console.log("chamou o filtro");
        this.$loading.show();

        this.filter = payload;

        await Promise.all([
          //
          this.loadChart(payload),
          //
          this.loadResume(payload)
        ]);

        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    /**
     * Carrega o gráfico
     */
    async loadChart({ month }) {
      const { data } = await this.$api.get("/financials/chart", {
        month
      });

      this.chart = data.data;
    },

    /**
     * Carrega o resumo
     */
    async loadResume(payload) {
      const { data } = await this.$api.get("/financials/resume", payload);

      this.resume = data.data;
    },

    /**
     *
     */
    openToAdd() {
      this.$refs["dialog_save"].open({ multiplier: "-1" });
    },

    /**
     * Cria uma nova cobrança
     */
    openToAddCharge() {
      this.$refs["dialog_add_charge"].open();
    },

    /**
     * Cria uma nova cobrança
     */
    openToBatchUpdate() {
      this.$refs["dialog_batch_update"].open();
    }
  },

  mounted() {},

  created() {
    // Tabelas auxiliares usadas nos filtros do formulário
    this.$store.dispatch("financial_statuses/all");
    this.$store.dispatch("financial_categories/all");
    this.$store.dispatch("financial_methods/all");
    this.$store.dispatch("financial_gateways/all");
    this.$store.dispatch("financial_connections/all");
  }
};
</script>

<style>
</style>
